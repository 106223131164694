<template>
  <el-container style="height:750px">
    <el-main>
        <div style="width: 1500px;margin:0 auto;margin-top: 20px;height: 80%;border: 1px solid #ccc;box-shadow: 1px 4px 10px grey;">
          <ul id="gameToolsListUl" style="overflow:auto;height: 100%">
            <li>
              <a target="_blank" href="https://flingtrainer.com/">1. 《风灵月影》修改器官方免费下载： https://flingtrainer.com/  【官网免费】</a>
            </li>
            <li>
              <a target="_blank" href="https://www.xiaoxingjie.com/">2. 《小幸修改器》修改器工具：https://www.xiaoxingjie.com/</a>
            </li>
            <li>
              <a target="_blank" href="https://dl.3dmgame.com/patch/xiugaiqi_1_hot.html">3. 《3DM修改器合集》：https://dl.3dmgame.com/patch/xiugaiqi_1_hot.html</a>
            </li>
            <li>
              <a target="_blank" href="https://pan.baidu.com/s/1xW9WKXmfV8mOcBfUxEa7zQ?pwd=e770">4. 《运行库工具合集》: https://pan.baidu.com/s/1xW9WKXmfV8mOcBfUxEa7zQ?pwd=e770 提取码：e770  【链接内有其他修改器】</a>
            </li>
          </ul>
        </div>
    </el-main>
  </el-container>
</template>
<script>

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
  },
  data() {
    return {
    };
  },
  filters: {
  },
  // 卸载
  beforeDestroy() {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    goHome(){
      window.location.href='/'
    }
  },
};
</script>

<style scoped>
body {
  height: 100vh;
  background-color: #f4fafe;
}

.zoomed-in {
  transform: scale(1.2); /* 放大1.2倍 */
  transition: transform 0.3s ease; /* 动画过渡效果 */
}

.zoomed-out {
  transform: scale(1); /* 放大1.2倍 */
  transition: transform 0.3s ease; /* 动画过渡效果 */
}

#headerUl>li{
  float: left;
  list-style: none;
}

a:hover{
  color:#1a284a;
  font-size: 15px;
}
.dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #fd721f;
  display: inline-block;
}
#gameToolsListUl>li{
  list-style: none;
  margin-top: 10px;
  margin-left: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  opacity: 0.9;
  color: blue;
}

li a{
  text-decoration: none;
}

#gameToolsListUl>li:hover{
  text-decoration: underline;
}
.input-class .el-input__inner {
  text-align: center;
  border-radius: 30px;
  width: 380px;
  float: right;
  margin-right: 30%;
  margin-top:20px;
}

.glass-effect {
  height: 500px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}
/*.el-main{*/
/*  background: url("http://154.197.99.71:8082/file/bg02.jpg");*/
/*  !*background-size: contain;*!*/
/*}*/
</style>
